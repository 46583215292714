import { mapGetters } from 'vuex'
import portfolio from '../../../constant/portfolio'

export default {
  name: 'corporate-solutions',
  data: () => ({
    solutionList: [
      {
        solutionName: 'Talent Pipeline',
        subTitle: 'Providing best digital talent for your company',
        description: [
          'We understand the importance of having a reliable and skilled workforce.',
          'With our comprehensive recruitment process and experienced team, you can trust that we will provide top-quality talents to support your business needs.'
        ],
        image: 'illustration/g2lab.svg',
        buttonUrl: '/talent-pipeline',
        contentReverse: false
      },
      {
        solutionName: 'Corporate Training',
        subTitle: 'Upskill your organization with corporate training',
        description: [
          "Elevate your team's skills and performance with our corporate training services. We offer a wide range of training options tailored to your company's specific needs, from technical skills training to leadership development.",
          'With our experienced trainers and interactive learning techniques, you can trust that your team will come away from our training programs empowered to succeed in their roles.'
        ],
        image: 'corporate/corporate_02.png',
        buttonUrl: '/corporate-training',
        contentReverse: true
      },
      {
        solutionName: 'Project & Development',
        subTitle: 'Delivering digital strategies designed for your business success',
        description: [
          'Need help bringing your project or software to life?',
          'Our development services can help. With a team of experienced professionals and a proven track record of delivering high-quality solutions, you can trust us to support your project or software development needs from start to finish.'
        ],
        image: 'corporate/corporate_03.png',
        buttonUrl: '/g2lab',
        contentReverse: false
      }
    ],
    currentPortofolioPage: 1,
    pageSize: 6,
    isVisibleDetail: false,
    selectedPortfolio: {}
  }),
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    videoLink() {
      return 'https://www.youtube.com/embed/TCxP4l1qjfk'
    },
    partnersCol1() {
      return this.isMobile ? this.partners.slice(0, 3) : this.partners
    },
    partnersCol2() {
      return this.partners.slice(3, 5)
    },
    partnersCol3() {
      return this.partners.slice(5, 7)
    },
    portfolioList() {
      return portfolio.slice(0, this.currentPortofolioPage * this.pageSize)
    },
    totalPortfolio() {
      return portfolio.length
    }
  },
  methods: {
    // paginate(arr, size) {
    //   return arr.reduce((acc, val, i) => {
    //     const idx = Math.floor(i / size)
    //     const page = acc[idx] || (acc[idx] = [])
    //     page.push(val)
    //     return acc
    //   }, [])
    // },
    toggleVisibleDetail(value) {
      this.selectedPortfolio = value || {}
      this.isVisibleDetail = !this.isVisibleDetail
    },
    loadMore() {
      if (this.portfolioList.length !== this.totalPortfolio) {
        this.currentPortofolioPage++
      }
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    },
    toPartner() {
      this.$router.push({
        path: '/register-alliance',
        query: {
          assign: 'MANPOWER'
        }
      })
    },
    toSolution(item) {
      this.$router.push(item)
    }
  }
}
